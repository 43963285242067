import React from 'react';

type ButtonProps = {
  onClick?: () => void;
  label: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
};

const textStyle: React.CSSProperties = {
  position: 'relative',
  letterSpacing: '0.1em',
  lineHeight: '32px',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '18px',
};

const buttonStyle: React.CSSProperties = {
  boxShadow: '0px 4px 24px rgba(0, 87, 255, 0.25)',
  backgroundColor: '#0057ff',
  padding: '16px 32px',
  color: '#fff',
  fontFamily: 'Poppins',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
};

export const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  type = 'button',
  disabled = false,
  className = '',
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{ ...buttonStyle, cursor: disabled ? 'not-allowed' : 'pointer' }}
      className={className}
    >
      <span style={textStyle}>{label}</span>
    </button>
  );
};
