import { useEffect, useState } from 'react';
import './App.css';
import { Button } from './components/button';
import desktop_bg from './images/desktop_bg.svg';
import full_logo from './images/full_logo.svg';
import mobile_bg from './images/mobile_bg.svg';
import tablet_bg from './images/tablet_bg.svg';

enum ScreenSize {
  Desktop,
  Tablet,
  Mobile,
}

function App() {
  const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.Desktop);

  useEffect(() => {
    const updateScreenSize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setScreenSize(ScreenSize.Mobile);
      } else if (width <= 1024) {
        setScreenSize(ScreenSize.Tablet);
      } else {
        setScreenSize(ScreenSize.Desktop);
      }
    };

    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);

    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const appStyle = {
    backgroundImage:
      screenSize === ScreenSize.Desktop
        ? `url(${desktop_bg})`
        : screenSize === ScreenSize.Tablet
        ? `url(${tablet_bg})`
        : `url(${mobile_bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
  };

  const contentStyle: React.CSSProperties = {
    filter: 'drop-shadow(0px 4px 24px rgba(87, 76, 255, 0.15))',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: screenSize === ScreenSize.Mobile ? '24px' : '48px',
    textAlign: 'center',
  };

  const headerStyle: React.CSSProperties = {
    fontSize: screenSize === ScreenSize.Mobile ? '28px' : '48px',
    letterSpacing: '0.02em',
    lineHeight: screenSize === ScreenSize.Mobile ? '32px' : '56px',
    display: 'inlineBlock',
    fontFamily: 'Poppins',
    whiteSpace: 'pre-line',
  };

  const subheaderStyle: React.CSSProperties = {
    fontSize: screenSize === ScreenSize.Mobile ? '20px' : '24px',
    lineHeight: screenSize === ScreenSize.Mobile ? '28px' : '36px',
    padding: screenSize === ScreenSize.Mobile ? '0px 10px' : '0px',
    fontFamily: 'Kumbh Sans',
    textAlign: 'center',
    margin: 0,
  };

  const handleClick = () => {
    window.location.href = 'https://tally.so/r/mD5P9q'; 
  };

  return (
    <div style={appStyle}>
      <div style={contentStyle}>
        <img src={full_logo} style={{ height: '36px' }} alt='logo' />
        <div style={headerStyle}>
          {'Focus on scaling your startup\nwe’ll handle the rest'}
        </div>

        <div style={subheaderStyle}>
          <div>{`Automate back-office tasks, centralize operations, and ensure compliance with `}</div>
          <div>
            <span style={{ color: '#0057ff' }}>Nexel</span>
            <span> your AI-powered operations platform.</span>
          </div>
        </div>
        <Button label='Get Access' onClick={handleClick} className='primary' />
      </div>
    </div>
  );
}

export default App;
